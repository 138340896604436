import React from 'react';
import styled from 'styled-components';
import ArchivePost from './ArchivePost';

import Pagination from './Pagination';
import theme, { media } from './theme';

const Blog = props => {
  const { posts, perPage, numPages, currentPage } = props;
  return (
    <StyledBlogPage className="blog page">
      <h1 className="page__title">Latest</h1>
      <ul className="posts-list">{posts.length ? posts.map(post => <ArchivePost key={post.id} {...post} />) : <p>No Posts Yet!</p>}</ul>

      <Pagination numPages={numPages} currentPage={currentPage} />
    </StyledBlogPage>
  );
};

const StyledBlogPage = styled.div`
  .page {
    &__title {
      background: ${({ theme }) => theme.light};
      color: ${({ theme }) => theme.blue};
      text-transform: uppercase;
      text-align: center;
      padding: 1rem 0;
      margin: 0;
    }
  }

  .posts-list {
    padding: 0 3rem;
  }

  ${media.break`
    background: white;
    display: grid;
    grid-template-rows: repeat(3, auto);
    width: ${({ theme }) => theme.sizes.large}px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    .page {
      &__title {
        grid-row: 1 / 2;
        background: none;
        text-align: left;
        padding: 1rem 3rem;
        color: ${({ theme }) => theme.red};
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          background: ${({ theme }) => theme.red};
        }
      }
    }
  `}
`;

export default Blog;
