import React from 'react';
import { graphql } from 'gatsby';

import Blog from '../components/Blog';

const blog = ({ data, pageContext, path, location }) => {
  console.log(data.allSanityPost);
  return <Blog posts={data.allSanityPost.edges.map(edge => ({ ...edge.node }))} {...pageContext} />;
};

export const BlogQuery = graphql`
  query blogQuery($skip: Int!, $limit: Int!) {
    allSanityPost(skip: $skip, limit: $limit) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          excerpt
        }
      }
    }
  }
`;

export default blog;
